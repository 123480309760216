import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import ContactBar from "../../components/ContactBar";
import SingleImage from "../../library/SingleImage";
import Layout from "../../components/Layout";

const MenstruationsPage = (props) => (
  <Layout location={props.location}>
    <div className="page offer">
      <Helmet>
        <title>Menstruationsstörungen</title>
        <meta
          name="description"
          content="Menstruationsbeschwerden können mit Akupunktur und chinesischen Kräutern gut behandelt werden"
        />
        <meta property="og:title" content="Menstruationsbeschwerden" />
        <meta
          property="og:description"
          content="Menstruationbeschwerden können mit Akupunktur und chinesischen Kräutern gut behandelt werden"
        />
      </Helmet>
      <ContactBar className="large-screen" />
      <div className="container">
        <h1>Menstruations-Beschwerden</h1>
        <SingleImage image={props.data.menstruationsstoerungen.fluid} />
        <p>
          Die zyklusabhängigen Beschwerden können sehr vielschichtig auftreten.
          Sie können sich als <strong>Blutungsstörungen</strong> manifestieren,
          z.B. in Form von sehr starken Blutungen, dem Ausbleiben der Blutung
          sowie verkürzten oder verlängerten Zyklen.
        </p>
        <p>
          Auch Schmerzen vor und während der Menstruation sind leider keine
          Seltenheit. Jede 2. Frau leidet unter{" "}
          <strong>Bauch- und Rückenschmerzen</strong> während der Blutung.
          Einige Frauen greifen jeden Monat auf Schmerzmittel zurück und in
          schweren Fällen sind sie nicht arbeitsfähig. In solchen Fällen spricht
          die TCM von Qi- oder sogar Blutstagnation. Das Ziel ist die Energie
          und das Blut zu bewegen und damit die Blockaden aufzulösen.
        </p>
        <p>
          <strong>Kopfschmerzen</strong> oder Migräne zum Zeitpunkt des
          Eisprungs und zu Beginn der Menstruation können auf ein aufsteigendes
          Leber Yang hindeuten.
        </p>
        <p>
          <strong>PMS, das Prämenstruelle Syndrom</strong>, umfasst eine
          Vielzahl von Symptomen und Beschwerden, welche in der 2. Zyklushälfte,
          vermehrt in den letzten Tagen vor der nächsten Blutung auftreten.
          Brustspannen, emotionale Schwankungen, Reizbarkeit, Antriebslosigkeit,
          unreine Haut und Heißhunger sind nur die gängigsten Beschwerden in
          dieser Zeit. Diesen Symptomen liegt meistens eine Leber Qi Stagnation
          zu Grunde. Bewegung, ausgewogene Ernährung, Yoga und erholsamer Schlaf
          können in dieser Zeit sehr hilfreich sein.
        </p>
        <p>
          Die Gründe sind genauso vielschichtig, wie die Beschwerden selber.
          Stress, hormonelle Schwankungen, gynäkologische Erkrankungen oder
          Spätfolgen vorangegangener Operationen können Menstruationsstörungen
          auslösen.
        </p>
        <p>
          Die TCM erstellt aufgrund der individuellen Symptome eine Diagnose
          welche z.B. mit Akupunktur, chinesischen Kräutern und
          Ernährungsempfehlungen behandelt wird.
        </p>
        <div className="cta">
          <p>Buchen Sie gleich jetzt einen Termin zur Erstanamnese!</p>
          <a
            className="button"
            href="https://www.coachfrog.ch/de/fachperson/paola-hoffmann/?bookingOpen=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            Online buchen
          </a>
        </div>
      </div>
    </div>
  </Layout>
);

export default MenstruationsPage;

export const query = graphql`
  query MenstruationsImageQuery {
    menstruationsstoerungen: imageSharp(
      fluid: { originalName: { regex: "/menstruation/" } }
    ) {
      fluid(maxHeight: 580, cropFocus: ENTROPY) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;
